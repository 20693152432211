@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&display=swap');

@font-face {
  font-family: 'big-text'; /* Remplacez "Nom de la police" par le nom de la police que vous avez téléchargée */
  src: url('./assets/fonts/vintagemohaidemoversionregular-vmlbo.ttf'); /* Remplacez "nom-de-la-police.ttf" par le nom du fichier de police téléchargé */
}



@font-face {
  font-family: 'small-text';
  src: url('./assets/fonts/IvyMode-Regular.ttf');
}


body{
    margin: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    background-color: black;
    

        //  .old{

    
        //   width: 100%;
        //   height: 100%;
        //   position: fixed;
        //   top: 0;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   background-image: url('./assets/vintage.webp');          
        //   opacity: .05;
        //   background-repeat: no-repeat;
        //   background-size: cover;
        //   background-position: center center;
        //   z-index: 150;
        //   pointer-events: none;
        // }
   
::-webkit-scrollbar {
    width: 0;
    height: 0;
  }


  main{
    perspective: 1px;
    overflow-x: hidden !important;
  }


}

.small{
    font-family: 'small-text';
  }
  
  .big{
    font-family: 'big-text';
  }

  .responsive-eraser{
    @media screen and (max-width: 1000px) {
       display: none;
      }
}