.header-all{
font-size: 1vw;

.nav{
    padding: 2rem;
    @media screen and (max-width: 750px) {
        padding: 2rem 1rem ;

    }

    display: flex;
    justify-content: space-between;
    
    .logo{
        font-weight: 900;
        img{
            width: 7vw;
            @media screen and (max-width: 750px) {
                width: 28vw;

            }
           
        }
        p{
            font-size: 1.7vw; 
            @media screen and (max-width: 750px) {
                font-size: 10vw; 

            }

        }
    }

    p{
        margin: 0;
        font-size: 1.45vw;   
        cursor: pointer;  
    }

  
    span{
        font-size: 0.8vw;
        margin-top: 0;       
    }

    .infos{
        
        display: flex;
        flex-direction: row;
        img{
            width: 1.8vw;
            height: 1.8vw;
        }

        p{
            margin-left: 2rem;
        }
        p.underline{
            text-decoration: underline;
        }

        @media screen and (max-width: 750px) {
            display: none;

        }
    }
    .infos-tgl{
        display: flex;
        flex-direction: row;
        p{
            padding-top: 3vw;
            font-size:4vw;
            text-decoration: underline;

        }
        img{
            width: 14vw;
            height: 14vw;
            opacity: 1;
        }

        @media screen and (min-width: 750px) {
            display: none;

        }
    }
}


.header-text{
    display: flex;
    justify-content: center;
    flex-direction: column;
    place-items: center;
    margin-top: 3.2vw;
    h1{
        font-size: 6.4vw;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 100;
        @media screen and (max-width: 750px) {
            font-size: 10vw;
            margin-top: 1.5rem;
        }
        
    }
    p{
       width: 30%; 
       text-align: center;
       font-size: 1.4vw;
       margin-bottom: 0;
       @media screen and (max-width: 750px) {
        font-size: 4vw;
        width: 90%;
        margin-top: 1.5rem;
        line-height: 1.7rem;

    }
    

    }
}
    
}