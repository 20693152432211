.test{
    overflow-y: visible !important;
    position: absolute;
    top: 0;
    left: 10;
    // transform: translate(-50%, 0%);
    width:  100vw;
    height: 100vh;
    /* background-color: yellow; */
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
  
   
 
      
        .img-container{
            margin-top: 0;
            padding-top: 0;
          
            display: grid;
            grid-gap: 40px;
            grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
        
            @media screen and (max-width: 1000px) {
                display: flex;
                justify-content: center;
                place-items: center;
            }
            
    
            
            
    
            .box-img{
                position: relative;
                display: flex;
                place-content: center;                        
                flex-direction: column;
                
                img{
                    width: 100%;
                    border-radius: 20px;
                    margin-top:  1rem;
                    
                }  
                 
                video{
                    width: 100%;
                    border-radius: 20px;
                    margin-top:  1rem;
                    
                }  
                         
            }   
            
            .responsive-eraser{
                @media screen and (max-width: 1000px) {
                   display: none;
                  }
            }
        } 

        .invisible-img{
            visibility: hidden;
        } 
      
}

